export const enhancer = (el) => {
  const title = el.getElementsByClassName('accordion__panel-title');
  for (let i = 0; i < title.length; i += 1) {
    if (!title[i].classList.contains('accordion__panel--expanded')) {
      title[i].classList.add('accordion__panel--collapse');
    }
    title[i].addEventListener('click', function(e) {
      if (this.classList.contains('accordion__panel--collapse')) {
        this.classList.remove('accordion__panel--collapse');
        this.classList.add('accordion__panel--expanded');
      } else {
        this.classList.remove('accordion__panel--expanded');
        this.classList.add('accordion__panel--collapse');
      }
    });
  }
};
